import Layout from "@components/Layout";
import styled from "@emotion/styled";
import ServiceColumn from "@components/ServiceColumn";
import SEO from "@components/SEO";
import metaData from "@data/metaData.json";
import { container } from "../../assets/style/GeneralStyleComponent";
import data from "@data/services.json";
import { css } from "@emotion/react";
import { mdLayout } from "@variables/layout";
import { PADDING_12, PADDING_9 } from "@variables/paddings";
import HeroSection from "@components/HeroSection";

const ServicesPage = () => {
  return (
    <Layout>
      <SEO
        title={metaData.services.title}
        description={metaData.services.description}
        {...metaData.services}
      />

      <HeroSection
        css={hero_section_style}
        title={data.heroSection.title}
        description={data.heroSection.description}
        imgSrc={data.heroSection.image}
        imgAlt={data.heroSection.imageTitle}
      />

      <Row>
        {data.services.map((service, index) => (
          <ServiceColumn key={`service-card-${index}`} data={service} />
        ))}
      </Row>
    </Layout>
  );
};

export default ServicesPage;

const Row = styled.div`
  ${container}
  position: relative;

  padding-top: ${PADDING_9};
  display: grid;
  justify-content: space-evenly;
  gap: 100px;
  padding-bottom: ${PADDING_12};
  position: relative;
  flex-wrap: wrap;
  grid-template-columns: 30% 30%;

  &::before {
    content: "SERVICES";
    font-size: 12.7vw;
    position: absolute;
    top: -11vw;
    font-weight: 900;
    z-index: 1;
    opacity: 0.1;
    grid-area: 1 / 1 / 1 / 3;
  }
  @media ${mdLayout} {
    grid-template-columns: 70%;
  }
`;

const hero_section_style = css`
  .hero-section__title {
  }
  .hero-section__description {
    max-width: 50ch;
  }
  .hero-section__image {
    width: 40%;
  }
`;
