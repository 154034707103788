import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";
import Icon from "./Icon";
import Typography from "./Typography";
import { css } from "@emotion/react";
import { BORDERRADIUS_1, BORDERRADIUS_2 } from "@variables/border";
import colors from "@variables/colors";
import { BOXSHADOW_2 } from "@variables/box-shadows";
import { FONTSIZE_16, FONTSIZE_5, FONTSIZE_9 } from "@variables/font-sizes";
import { LIENHEIGHT_3 } from "@variables/text";
import { MARGIN_3 } from "@variables/margins";
import { mdLayout } from "@variables/layout";
import { PADDING_4 } from "@variables/paddings";
import { fontFamily, fontWeight } from "../assets/style/font-styles";
import {
  section_description,
  visually_hidden,
} from "../assets/style/GeneralStyleComponent";

const ServiceColumn = ({ data }) => {
  return (
    <Column>
      <ServiceLink to={data.url} color={data.theme}>
        <Icon name={data.icon} size="large" />
        <span>{data.title}</span>
      </ServiceLink>

      <Typography css={title_style} as="h2" content={data.title} />
      <Typography css={breif_style} as="p" content={data.brief} />
      <List>
        {data.types.map((el, i) => (
          <ListItem key={`service-column-${i}`} color={data.theme}>
            <Link className="p-2 sec-font" to={data.url + "#" + el.id}>
              {" "}
              {el.title}
            </Link>
          </ListItem>
        ))}
      </List>
    </Column>
  );
};

export default ServiceColumn;

const title_style = css`
  ${FONTSIZE_9}
  text-transform: uppercase;
`;

const breif_style = css`
  ${section_description}
  ${FONTSIZE_5}
  font-weight: ${fontWeight("medium")};
  padding: ${PADDING_4} 0;
  line-height: ${LIENHEIGHT_3};
`;

const List = styled.ul`
  font-weight: ${fontWeight("light")};
  list-style: none;
`;

const ListItem = styled.li<{ color: string }>`
  ${FONTSIZE_5}
  font-family: ${fontFamily("Montserrat")};
  padding-bottom: 18px;
  padding-left: 29px;
  a {
    color: ${colors.blue[800]};
    &:hover {
      color: ${(props) => props.color};
    }
  }
  &:before {
    content: "";
    width: 19px;
    height: 19px;
    background: ${colors.mintGreen[400]} 0% 0% no-repeat padding-box;
    background-color: ${(props) => props.color};

    box-shadow: ${BOXSHADOW_2};
    border-radius: ${BORDERRADIUS_2};
    position: absolute;
    left: 0;
  }
`;

const ServiceLink = styled(Link)<{ color: string }>`
  &,
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    min-width: 150px;
    min-height: 150px;
    width: 4em;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.mintGreen[400]} 0% 0% no-repeat padding-box;
    box-shadow: ${BOXSHADOW_2};
    border-radius: ${BORDERRADIUS_1};
    background-color: ${(props) => props.color};
    margin-bottom: ${MARGIN_3};
    font-size: ${FONTSIZE_16};
  }
  span {
    ${visually_hidden}
  }
`;
const Column = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  a,
  a:hover {
    color: ${colors.white};
  }

  @media ${mdLayout} {
    width: 90%;
    .img-contaianer {
      width: 150px;
      height: 150px;
    }
  }
`;
